// externals
import axios from 'axios';

// utils
import { getQueryParams } from './utils/querystring';

// config
import * as Config from '../config';

// interfaces/types
import type { FetchOfferDealXg, FetchOfferDealXgResults } from './types/fetchOfferInterfaces';

/**
 * Fetch the DealXg Offer
 * @param context dealXg params
 */
export const fetchOfferDealXg = async (context: FetchOfferDealXg): Promise<FetchOfferDealXgResults> => {
    // if we are mocking get this result
    if (isMockFetchOfferDealXg()) {
        return mockFetchOfferDealXg();
    }

    // get the URL for the api
    const domain = Config.getdsApiUrl('');
    const url = `${domain}/sp/bff/deal/fetchOfferDealXg`;
    const { dealXgId, dealXgVersion } = context;
    const payload = { dealXgId, dealXgVersion };

    // now make the axios call with the offer as the payload
    const result = await axios.post<FetchOfferDealXgResults>(url, payload);

    // if we get a problem, then we need to throw the error and stop
    if (!result.data.success) {
        const { error } = result.data;
        const { message = 'Could not fetch the dealXg offer.' } = error || {};

        throw new Error(message);
    }

    // now we have the result, we can transform it here
    return result.data;
};

export const isMockFetchOfferDealXg = (): boolean => {
    const qs = getQueryParams();
    return qs && qs.mockFetchOfferDealXg !== undefined && ['yes', 'true'].indexOf(qs.mockFetchOfferDealXg as string) >= 0;
};

/**
 * Mock call to fetchOfferDealXg so we can test without the API
 */
export const mockFetchOfferDealXg = (): FetchOfferDealXgResults => {
    return {
        success: true,
        offer: {
            connectionId: '620283edb179dcf83c2a3024',
            vin: '1HGCV1F11LA150252'
        }
    };
};
