'use strict';
var QueryParams = require('./widget_modules/queryParams');

var config = module.exports = {};

var env_configs = {};
env_configs.services = {};
env_configs.logstash_env = 'non-prod';

var dealStarterQSEnv = QueryParams.getParam('dsEnv');
var osirisEnv = QueryParams.getParam('osirisEnv');

var CONFIG_ENV = dealStarterQSEnv || process.env.NODE_ENV;
var OSIRIS_ENV = osirisEnv || process.env.NODE_ENV; // Should this reflect changes to just point to osiris by default?

function isLegacyIE() {
    return navigator.userAgent.indexOf('MSIE 10') > -1 ||
            navigator.userAgent.indexOf('MSIE 9') > -1 ||
            navigator.userAgent.indexOf('MSIE 8') > -1 ||
            navigator.userAgent.indexOf('MSIE 7') > -1;
}

switch (CONFIG_ENV) {
    case 'production':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget.makemydeal.com'
        };

        env_configs.logstash_env = 'prod';
        break;

    case 'dark-prod':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget.makemydeal.com'
        };

        env_configs.logstash_env = 'prod';
        break;

    case 'uat':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget.makemydeal.com'
        };
        break;

    case 'staging':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget-staging.makemydeal.com'
        };
        break;

    case 'staging2':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget-staging2.makemydeal.com'
        };
        break;

    case 'qa':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget-qa.makemydealpreview.com'
        };
        break;

    case 'qa2':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget-qa2.makemydealpreview.com'
        };
        break;

    case 'dev':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget-dev.makemydealpreview.com'
        };
        break;

    case 'local-production':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget.makemydeal.com'
        };
        break;

    case 'local':
        env_configs.services.api = {
            protocol: 'http://',
            host: 'w2.makemydealpreview.com:5000'
        };
        break;

    case 'local-ssl':
        env_configs.services.api = {
            protocol: 'http://',
            host: 'w2.makemydealpreview.com:5000'
        };
        break;

    default: // defaults to localhost
        env_configs.services.api = {
            protocol: 'http://',
            host: 'localhost:5000'
        };

}

config.logstash_env = env_configs.logstash_env;

config.services = {
    api: {
        // IE10, IE9 needs to use the current protocol for CORS requests
        // IE7 will be returned in compatibility mode
        baseApiUrl: (isLegacyIE() ?
            document.location.protocol + '//' :
            env_configs.services.api.protocol) + env_configs.services.api.host,
        // Load widgetUrl using configured protocol
        baseWidgetUrl: env_configs.services.api.protocol + env_configs.services.api.host,
        baseWidgetProtocol: env_configs.services.api.protocol
    },
    dsApi: {
        endpoints: {
            verify: 'ds/verify',
            experience: 'ds/experience'
        }
    }
};

config.getdsApiUrl = function(sponsor) {
    var domain = 'shop.dealer.com';

    if(sponsor.toLowerCase() === 'kbb' || sponsor.toLowerCase() === 'atc') {
        domain = 'accelerate.dealer.com';
    }

    switch (CONFIG_ENV) {
        case 'production':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: domain
            };
            break;
    
        case 'dark-prod':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: 'dark.' + domain
            };
            break;

        case 'staging':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: 'staging.' + domain
            };
            break;

        case 'uat':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: 'uat.' + domain
            };
            break;

        case 'qa':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: 'qa.' + domain
            };
            break;

        case 'dev':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: 'dev.' + domain
            };
    
            break;
    
        case 'local-production':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: domain
            };
    
            break;
    
        case 'local':
            env_configs.services.dsApi = {
                protocol: 'http://',
                host: 'dealstarter.makemydeal.dev:5501'
            };
    
            break;
    
        case 'local-ssl':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: 'dealstarter.makemydeal.dev'
            };
    
            break;
    
        default: 
            env_configs.services.dsApi = {
                protocol: 'http://',
                host: 'w2.makemydealpreview.com:5501'
            };
    }

    return (isLegacyIE() ?
        document.location.protocol + '//' :
        env_configs.services.dsApi.protocol) + env_configs.services.dsApi.host;
};

config.getVerifyApiUrl = function(version) {
    var gateway;
    var protocol = isLegacyIE() ? document.location.protocol + '//' : 'https://';

    switch(CONFIG_ENV) {
        case 'production':
        case 'local-production':
            gateway = 'gw-prod-live.awsdrst';
            break;
        case 'dark-prod':
            gateway = 'gw-prod-dark.awsdrst';
            break;
        case 'uat':
            gateway = 'gw-uat.awsdrst';
            break;
        case 'qa':
            gateway = 'gw-qa.awsdrstnp';
            break;
        default: 
            gateway = 'gw-dev.awsdrstnp';
            break;
    }

    return `${protocol}${gateway}.makemydeal.com${getVerifyApiUri(version)}`;
};

config.getWidgetLogUrl = function() {
    var gateway;
    var protocol = '';
    try{
        protocol = isLegacyIE() ? document.location.protocol + '//' : 'https://';
    }catch(error){
        log(error);
    }
    

    switch(CONFIG_ENV) {
        case 'production':
        case 'local-production':
            gateway = 'gw-prod-live.awsdrst';
            break;
        case 'dark-prod':
            gateway = 'gw-prod-dark.awsdrst';
            break;
        case 'uat':
            gateway = 'gw-uat.awsdrst';
            break;
        case 'qa':
            gateway = 'gw-qa.awsdrstnp';
            break;
        default: 
            gateway = 'gw-dev.awsdrstnp';
            break;
    }

    return `${protocol}${gateway}.makemydeal.com/widget/log`;
}
config.getNewRelicConfig = function() {
    return {
        environment: CONFIG_ENV || 'development',
        API_KEY: 'TjrU3blPNZwPgz9f1cxZ4eHj_AIkSmis',
        URL: 'https://insights-collector.newrelic.com/v1/accounts/1840109/events'
    };
}

config.getPencilHostUrl = function() {
    switch (OSIRIS_ENV) {
        case 'nonprod':
            return 'https://dr3-assembler-nonprod-us-east-1.service.web-np.dealer.com';
        default:
            return 'https://8083.localport.dealer.com';
    }
};

config.getMainHostUrl = function() {
    switch (OSIRIS_ENV) {
        case 'nonprod':
            return 'https://dr3-assembler-nonprod-us-east-1.service.web-np.dealer.com';
        default:
            return 'https://8082.localport.dealer.com';
    }
};

const getVerifyApiUri = (version = '5.0.0') => {
    let uri = '';
    if(version >= '6.0.0'){
        uri = '/v2/verify/init';
    } else {
        uri = '/verify/init';
    }

    return uri;
};

config.getVerifyApiUri = getVerifyApiUri;
