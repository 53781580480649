import axios from 'axios';
import * as Config from '../config';
import { Logger } from '../utils/logger';
import { IVerifyResponse } from 'lib/models/IVerifyResponse';

export function verifyData(data: IVerifyPayload, version = '5.0.0'): Promise<IVerifyResponse> {
    const logger: Logger = Logger.getLogger();
    const apiUrl = Config.getVerifyApiUrl(version);
    const messageContent = {
        dealerId: data.dealer.id,
        ownerId: data.dealer.refId,
        dnaAccountId: data.dealer.dnaAccountId,
        sponsor: data.dealer.sponsor,
        vehicle: {
            year: data.vehicle.year,
            listedPrice: data.vehicle.listedPrice,
            mileage: data.vehicle.mileage,
            type: data.vehicle.vehicleStatus
        }
    };

    return axios
        .post(apiUrl, messageContent)
        .then((response) => {
            if (response.status !== 200) {
                logger.error(response, 'verify-service-unavailable');
                throw response; // Throw and error if verify came back with an issue
            }

            return response;
        })
        .then((response) => {
            const verifyResponse: IVerifyResponse = response?.data?.response;

            if (!verifyResponse) {
                const err = Error('No response found in the Init call');
                logger.error(err, 'verify-failed');
                throw err;
            }

            return verifyResponse;
        });
}
interface IVerifyPayload {
    dealer: {
        id: string;
        refId: string;
        dnaAccountId: string;
        sponsor: string;
    };
    vehicle: {
        year: string;
        listedPrice: number;
        mileage: number;
        vehicleStatus: string;
    };
}
