// tslint:disable:variable-name
import * as _ from 'underscore';
import * as Q from 'q';
import { MessageBus } from './messagebus';
import { getPixallId } from './dca.accelerate';

/**
 * Represents an iframed application.
 * @param {object}
 * @param {DOM element}
 */

const frameOptions = [
    'name',
    'src',
    'target',
    'insertType',
    'onTargetFail',
    'style',
    'router',
    'routes',
    'onLoad',
    'onError',
    'wrapper',
    'onReconnect',
    'setSrc',
    'onSyncStyles',
    'pollWidgetVisibility',
    'onStartLoadingSpShell',
    'onRequestCadrObject',
    'onRequestAEId'
];

const frameIdPrefix = 'mmd-frame-';

export class AppFrame {
    public name;
    public instanceId;
    public origin: string;
    public target: any;
    public $target: any;
    public insertType: string;
    public onTargetFail: any;
    public src: string;
    public style: any;
    public router: any;
    public routes: any;
    public isDeferred: boolean;
    public isReady: boolean;
    public _deferred;
    public _readyDeferral;
    public $el;
    public $wrapper;
    public wrapper;
    public receiver;
    public onLoad;
    public onError;
    public defaultParentBodyOverflow;
    public title: string;

    constructor(private options: IAppFrameOptions) {
        this.name = options.frameId;
        this.instanceId = options.frameId;
        this.origin = '';
        this.target = '';
        this.insertType = 'append';
        this.onTargetFail = null;
        this.src = '';
        this.style = null;
        this.router = null;
        this.routes = {};
        this.isDeferred = false;
        this.isReady = false;
        this.title = options.title;
        this.defaultParentBodyOverflow = null;
        _.extend(this, _.pick(options, frameOptions));
        // Add instanceId to src
        let newSrcUrl = this.src;
        newSrcUrl += this.src.indexOf('?') >= 0 ? '&' : '?';
        newSrcUrl += 'instanceId=' + this.instanceId;
        this.src = newSrcUrl;

        this._deferred = null;

        // Resolved when ready is received
        this._readyDeferral = Q.defer();

        this._setEl();
        this._attach();
    }
    public setSrc(newSrcUrl) {
        newSrcUrl += this.src.indexOf('?') >= 0 ? '&' : '?';
        newSrcUrl += 'instanceId=' + this.instanceId;
        this.src = newSrcUrl;
        this.$el.src = this.src;
        this._readyDeferral = Q.defer();

        this.$el.onload = this.onLoad || null;
        this.$el.onerror = this.onError || null;
        this.setStyle(this.style, this.$el);
        this.setOrigin(this.src);
    }
    public _setReady() {
        if (this.isReady) {
            return this.onReconnect();
        }
        this.isReady = true;
        this._readyDeferral.resolve('ready');
        this.onSyncStyles();
    }
    public initialize() {}

    public onSuccess() {}

    public onFail() {}

    public onReconnect() {}

    public onSyncStyles() {}

    public onRequestCadrObject() {}

    public onRequestAEId() {}

    public pollWidgetVisibility() {}

    public onStartLoadingSpShell() {}

    public attach(options) {
        if (!this.isDeferred) {
            return console.log(this.name + ' is already attached');
        }

        _.extend(this, _.pick(options, frameOptions));

        this.isDeferred = false;
        this._attach();
    }

    public setStyle(cssRules, el) {
        el = el || this.$el;
        if (!el.style) {
            el.style = {};
        }
        for (const prop in cssRules) {
            if (cssRules.hasOwnProperty(prop)) {
                el.style[prop] = cssRules[prop];
            }
        }
    }

    public setOrigin(src) {
        const components = src.split('/');
        const protocol = components[0] + '//';
        const host = components[2];

        this.origin = protocol + host;
    }

    // Currently, if the frame fails to find a target
    // after it has been deferred, it will take in an
    // object which it uses to construct a dom element
    // to wrap the iframe in. You can tell it to attach to
    // the scripts parent by using useAnchor attribute.
    public _onTargetFailed() {
        // If you can't find target and frame has already been deffered
        // just log error.
        if (!this.onTargetFail && this.isDeferred) {
            console.log('Couldnt find target ' + this.target);
        }

        if (!this.onTargetFail) {
            this.isDeferred = true;
            return false;
        }

        if (typeof this.onTargetFail === 'function') {
            // Must return dom element of false or deferr.
            return this.onTargetFail.call(this);
        }

        if (typeof this.onTargetFail !== 'object') {
            return console.log('Needs to be a or an object.');
        }

        const failConfig = this.onTargetFail;
        const $container = document.createElement(failConfig.tag || 'div');

        if (failConfig.id) {
            $container.setAttribute('id', failConfig.id);
        }

        if (!failConfig.useAnchor) {
            return console.log('Need to define a target element.');
        }

        failConfig.useAnchor.parentNode.insertBefore($container, failConfig.useAnchor);
        return $container;
    }

    public setTarget() {
        if (this.target instanceof HTMLElement) {
            this.$target = this.target;
        } else {
            this.$target = this.target === 'body' ? document.body : document.querySelector(this.target);
        }

        // If there is no target DOM found, run through
        // the on failed action.
        if (!this.$target) {
            this.$target = this._onTargetFailed();
        }
    }

    public trigger(type, payload) {
        const message = {
            type,
            payload
        };

        // Wait until iframe is loaded before trying postMessage
        this._readyDeferral.promise.then(() => {
            this.$el = document.getElementById(frameIdPrefix + this.instanceId);
            this.receiver = this.$el.contentWindow;
            this.receiver.postMessage(JSON.stringify(message), this.origin);
        });
    }

    public triggerSync(payload, target) {
        target = target || '*';
        // bug/DE115840/pixallId adding the pixallId here because sometimes
        // the value doesnt come back before the widget/DS loaded;
        // this will make sure that the pixallId is always there
        const cookies = getPixallId();
        if (payload.shopper) {
            payload.shopper.pixallId = cookies;
        } else {
            payload.shopper = {};
            payload.shopper.pixallId = cookies;
        }
        const message = {
            message: JSON.stringify(payload),
            sourceId: 'MASTER',
            targetId: target,
            context: 'SYNC'
        };

        // Wait until iframe is loaded before trying postMessage
        this._readyDeferral.promise.then(() => {
            this.$el = document.getElementById(frameIdPrefix + this.instanceId);
            this.receiver = this.$el.contentWindow;

            this.receiver.postMessage(JSON.stringify(message), this.origin);
        });
    }

    public _setEl() {
        this.$el = document.createElement('iframe');
        this.$el.id = frameIdPrefix + this.instanceId;
        this.$el.allow = 'camera;microphone'; // allow permissions for livePerson video chat
        this.$el.src = this.src;
        this.$el.title = this.title;
        this.$el.onload = this.onLoad || null;
        this.$el.onerror = this.onError || null;
        this.setStyle(this.style, this.$el);

        this.setOrigin(this.src);

        if (this.wrapper) {
            this.$wrapper = document.createElement('div');
            this.$wrapper.id = this.wrapper.id;
            this.setStyle(this.wrapper.style, this.$wrapper);
            this.$wrapper.appendChild(this.$el);
        }
    }

    public _bindRoutes() {
        if (!this.routes) {
            return;
        }

        if (this.routes && !this.router) {
            return console.log('A router instance needs to be passed in.');
        }

        this.routes.ready = '_setReady';

        const handlers = _.values(this.routes);

        const mergeToRoot = (name) => {
            if (!this.options[name]) {
                return;
            }

            if (this[name]) {
                return console.log('A route handler is attempting to override an existing method.');
            }

            this[name] = this.options[name];
        };

        _.each(handlers, mergeToRoot, this);
        this.router.bindRoutes(this, this.routes);
    }

    public _attach() {
        this.setTarget();

        if (this.isDeferred) {
            return;
        }

        if (!this.$target) {
            return console.log('You need to specify a container for the frame. ' + this.name);
        }

        // Initialize hook
        this.initialize();
        this.insertType === 'append'
            ? this.$target.appendChild(this.$wrapper || this.$el)
            : this.$target.insertBefore(this.$wrapper || this.$el, this.$target.childNodes[0]);

        this.receiver = this.$el.contentWindow;
        this._bindRoutes();
    }

    public isMissing() {
        return !document.getElementById(frameIdPrefix + this.instanceId);
    }

    public isParentHidden(el, lim, isHiddenCallback) {
        lim = lim || 0;
        el = el || document.querySelector('#mmd-widget');

        const hidden = (el) => {
            return window.getComputedStyle(el).display === 'none' || window.getComputedStyle(el).visibility === 'hidden';
        };

        // control
        if (lim === 5) {
            return;
        }

        if (el !== null && el.nodeName === 'BODY') {
            return;
        }

        // get parent
        el = el.parentNode || null;

        // search up dom tree for hidden parent
        if (el) {
            if (hidden(el)) {
                isHiddenCallback(el, lim);
                return;
            } else {
                this.isParentHidden(el, lim + 1, isHiddenCallback);
            }
        }
    }
}

export interface IAppFrameOptions {
    name: string;
    src: string;
    frameId: string;
    target: string;
    title?: string;

    style: IAppFrameStyle;
    router: MessageBus;
    routes: IAppFrameRoutes;

    wrapper?: IWrapper;

    onResize: (options) => void;
    onOpen?: () => void;
    onShow?: () => void;
    onReconnect?: () => void;
    setSrc?: (newSrcUrl) => void;
    onTrack: (options?) => any;
    onLoadedFunction?: () => void;
    fieldUpdated: (options) => void;
    onClose?: () => void;
    onSetHeight?: (options) => void; // onHeightChange
    startAuryc?: () => void; // onInteraction or onFocus
    onRequestCadrObject?: () => void;
    onRequestAEId?: () => void;
    onSyncStyles?: () => void;
    onEnableAnimatedHeight?: () => void;
    pollWidgetVisibility?: () => void; // onLoad we poll and check visibility
    onStartLoadingSpShell?: () => void;
}
interface IAppFrameStyle {
    height: string;
    width: string;
    border: number;
    display?: string;
    clear?: 'both';
    padding?: number;
    margin?: number;
    overflow?: 'hidden';
}

interface IAppFrameRoutes {
    open?: 'onOpen';
    close?: 'onClose';
    show?: 'onShow';
    resize?: 'onResize';
    externalTrack?: 'onTrack';
    setHeight?: 'onSetHeight';
    fieldUpdated?: string;
    startAurycRecording?: 'startAuryc';
    setUseAnimatedHeight?: 'onEnableAnimatedHeight';
    startLoadingSpShell?: 'onStartLoadingSpShell';
    requestCadrObject?: 'onRequestCadrObject';
    requestAEId?: 'onRequestAEId';
    syncStyles?: string;
}

interface IWrapper {
    id: string;
    style: IWrapperStyle;
}

interface IWrapperStyle {
    display: 'none';

    position: 'fixed';
    right: number;
    bottom: number;
    left: number;
    top: number;

    border: number;
    overflow: 'scroll';
    '-webkit-overflow-scrolling': 'touch';
    'z-index': number;
}
