// externals
import * as qs from 'qs';

// interfaces/types
import type { SaveOfferResults, VerifyResults, SPSource } from './types/saveOfferTypes';
import type { SdpWidgetInitialization } from './types/initializationInterfaces';
import type { IQueryString } from './types/IQueryString';

// config
import * as Config from '../config';

// utils
import { getQueryParams, addToQueryParam, getQueryStringFirst } from './utils/querystring';
import { isSaveOffer } from './typeGuards/saveOffer';
import { getActivEngageId } from './utils/getActivEngageId';
import { SDPLogger } from './utils/sdpLogger';

export type SDPRoute =
    | 'dashboard'
    | 'tradeInManualEntry'
    | 'credit'
    | 'testDrive'
    | 'paymentOptions'
    | 'shopperLeadForm'
    | 'tradeInSearch'
    | 'shopper-platform'
    | 'dashboardDealSummaryModal'
    | 'docUpload';

export type SDPUrlOpts = {
    offerInfo: SaveOfferResults;
    source?: SPSource;
    verify?: VerifyResults;
    isMobile?: string;
    route?: SDPRoute;
    context?: SdpWidgetInitialization;
};

/**
 * Get the SDP URL from parameters
 * @param offerInfo The offer information (vin/connection id)
 * @param verify the result from verify
 * @param isMobile should we be in a mobile view?  'yes' or 'no'
 * @param route The route to go to SDP @default dashboard
 */
export const getSDPUrl = async (options: SDPUrlOpts): Promise<string> => {
    const { offerInfo, isMobile, verify, source, context } = options;
    const { route } = options;

    if (verify?.sponsor) {
        offerInfo.sponsor = verify.sponsor;
    }

    const { dealSetupTaxCallMade } = offerInfo;

    // get the querystring settings
    const query = await getQSOfferResults({
        offerInfo,
        isMobile,
        route,
        source,
        dealSetupTaxCallMade,
        verify
    });

    // dealer flow set these QS props if appropriate
    if (source === 'VIN') {
        query.dsRole = 'dealer';
        query.dsSource = 'VIN';

        const userRole = context.userRole || 'salesperson';
        if (userRole === 'desking_manager') {
            query.userRole = 'desking_manager';
            query.route = 'dashboard';
        } else {
            query.userRole = 'salesperson';
        }
    }

    const domain = Config.getdsApiUrl(query.sponsor || 'ddc');

    const querystring = qs.stringify(query);
    return `${domain}/sp/shells/main.html?${querystring}`;
};

const removeUndefined = (query: IQueryString): IQueryString => {
    const keys = Object.keys(query);
    keys.forEach((key) => {
        if (!query[key]) {
            delete query[key];
        }
    });

    return query;
};

export const getQSOfferResults = async (options: {
    offerInfo: SaveOfferResults;
    source?: SPSource;
    isMobile?: string;
    route?: string;
    dealSetupTaxCallMade?: boolean;
    verify?: VerifyResults;
}): Promise<IQueryString> => {
    // get out the options
    const { offerInfo, isMobile = 'no', route = 'dashboard', source = 'SPONSOR', dealSetupTaxCallMade, verify } = options;
    const { sponsor, vin, connectionId, snapshotId } = offerInfo; // do not default sponsor here
    const { isActivEngageEnabled } = verify || {};

    // parse the QS so we can get overrides if needed
    const currentQueryString = getQueryParams();

    // get from the querystring if it exists
    const {
        bypassDraasSettingsCache,
        dsDealExperience: dealExperience,
        isMinified,
        dsTheme: theme,
        dsThemeRedesign: themeRedesign,
        enableToggles,
        disableToggles,
        route: routeQS = route,
        spPr
    } = currentQueryString;

    const query: IQueryString = {
        spSource: source,
        connectionId,
        snapshotId,
        vin,
        isMobile,
        sponsor,
        route: getQueryStringFirst(routeQS),
        dealSetupTaxCallMade: dealSetupTaxCallMade?.toString(),
        dealExperience: getQueryStringFirst(dealExperience),
        theme: getQueryStringFirst(theme),
        themeRedesign: getQueryStringFirst(themeRedesign),
        isMinified: getQueryStringFirst(isMinified),
        enableToggles: addToQueryParam(enableToggles), // flattens the array if it is an array
        disableToggles: addToQueryParam(disableToggles), // flattens the array if it is an array
        spPr: addToQueryParam(spPr)
    };

    if (bypassDraasSettingsCache === 'true') {
        query.bypassDraasSettingsCache = bypassDraasSettingsCache;
    }

    // get the activEngageId
    const activEngageToken = await getActivEngageId(SDPLogger.getLogger(), isActivEngageEnabled);
    query.activEngageToken = activEngageToken;

    return removeUndefined(query);
};
